/**
 * Main wrapper
 */
 .select-customplans {
  max-width: 531px;
  width: 100%;
  font-size: 15px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}

.single-item{
  cursor: default !important;
}

.select-customplans *,
.select-customplans *::after,
.select-customplans *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-customplans__value {
  position: relative;
  /* z-index: 1; */
}

.select-customplans__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;

}

/**
* Input
*/
.select-customplans__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-size: 15px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
  color: #666;
  cursor: pointer;
  overflow: hidden;

}

.select-customplans__input::-webkit-search-decoration,
.select-customplans__input::-webkit-search-cancel-button,
.select-customplans__input::-webkit-search-results-button,
.select-customplans__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-customplans__input:not([readonly]):focus {
  cursor: default;
}

/**
* Options wrapper
*/
.select-customplans__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-customplans__options {
  list-style: none;
  margin: 0;
  padding-inline-start: 0;

}

/**
* Option row
*/
.select-customplans__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-customplans__option,
.select-customplans__not-found {
  display: block;
  min-height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-size: 18px;
  text-align: left;
  cursor: default;
  color: #666;
}

.select-customplans--multiple .select-customplans__option {
  height: 48px;
}

.select-customplans__option.is-selected {
  background: #3795FF;
  color: #fff !important;
}


.select-customplans__option:not(.is-selected):hover,
.select-customplans__option:not(.is-selected):active {
  background: #e5e9ee;
  color: #666666;
}


.select-customplans__option.is-highlighted {
  background: #e5e9ee;
  color: #666666;
}

.select-customplans__option.is-highlighted.is-selected,
.select-customplans__option.is-selected:hover {
  background: #3795FF;
  color: #fff;
}

/**
* Group
*/
.select-customplans__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  
}

/**
* States
*/
.select-customplans.is-disabled {
  opacity: 0.5;
}

.select-customplans.is-loading .select-customplans__value::after {
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E"); */
  background-size: 11px;
  
}

.select-customplans:not(.is-disabled) .select-customplans__input {
  cursor: default;
}

/**
* Modifiers
*/
.select-customplans--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-customplans:not(.is-loading):not(.select-customplans--multiple)
  .select-customplans__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-customplans--multiple .select-customplans__input {
  cursor: initial;
}

.select-customplans--multiple .select-customplans__input {
  border-radius: 3px 3px 0 0;
}

.select-customplans--multiple:not(.select-customplans--search) .select-customplans__input {
  cursor: default;
}

.select-customplans:not(.select-customplans--multiple) .select-customplans__select {
  position: absolute;
  z-index: 12;
  top: 56px;
  right: 0;
  left: 0;
  border-radius: 0 0px 3px 3px;
  overflow: auto;
  max-height: 223px;
  border-left:1px solid #ccc;
  border-right:1px solid #ccc;
  border-bottom:1px solid #ccc;
  overflow: hidden;
}

.select-customplans--multiple .select-customplans__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-customplans__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #666666;
}

.strike {	
	text-decoration: line-through;	
	text-decoration-color: red !important;	
  font-size: 12px !important;
  position: relative;
  top: -19px;
  font-weight: 400 !important;
  left: -111px;	
}
.per{
  font-size:13px;
}

.per::before{
  content: ' ';
}
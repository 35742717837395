.modal-content,
.back-button-modal .modal-content {
	padding: 14px 25px;
}
.modal-content .modal-header {
	border-color: #707070;
}
.modal {
	background-color: rgba(0, 0, 0, 0.5) !important;
}
.modal-content div{
	margin: 0 auto;
}